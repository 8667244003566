
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import NewCompanyModal from "@/views/companies/NewCompanyModal.vue";
import base from "@/api/base.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import * as Yup from "yup";

export default defineComponent({
  name: "companies",
  data() {
    const validation = Yup.object().shape({
      cmpRepNm: Yup.string().required("필수값입니다."),
      cmpAddr: Yup.string().required("필수값입니다."),
      cmpTel: Yup.string()
        .matches(/^\d{9,11}$/, "-를 제외한 9이상 11자리 이하 숫자를 입력하세요.")
        .required("필수값입니다."),
      cmpEmail: Yup.string()
        .required("필수값입니다.")
        .email("email 형식이 아닙니다."),
      cmpUseYn: Yup.boolean().required("필수값입니다."),
    });
    return {
      companies: { results: [{}] },
      detailcmp: {},
      searchcode: "cmp_nm",
      searchdata: "",
      status: "",
      page: 1,
      validation,
    };
  },

  components: {
    NewCompanyModal,
    Field,
    Form,
    ErrorMessage,
    VPagination,
  },
  created() {
    this.getListData();
  },

  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },
    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        cmp_use_yn: this.status,
        page: this.page,
      };
      base
        .getCompanyList(param)
        .then((res) => {
          console.log(res);
          this.companies = res.data;
        })
        .catch((err) => {
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    getData(id) {
      base
        .getCompany(id)
        .then((res) => {
          console.log(res);
          this.detailcmp = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editData() {
      base
        .updateCompany(this.detailcmp)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("회사 정보를 수정했습니다.");
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
});
