
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import base from "@/api/base.js";

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    const validation = Yup.object().shape({
      cmpNm: Yup.string().required("필수값입니다."),
      cmpRepNm: Yup.string().required("필수값입니다."),
      cmpAddr: Yup.string().required("필수값입니다."),
      cmpTel: Yup.string()
        .matches(
          /^\d{9,11}$/,
          "-를 제외한 9이상 11자리 이하 숫자를 입력하세요."
        )
        .required("필수값입니다."),
      cmpEmail: Yup.string()
        .required("필수값입니다.")
        .email("email 형식이 아닙니다."),
      cmpUseYn: Yup.boolean().required("필수값입니다.")
    });
    return {
      company: {},
      validation
    };
  },

  methods: {
    showApi() {
      const daum = (window as any).daum;
      new daum.Postcode({
        oncomplete: data => {
          this.company.cmp_addr = data.roadAddress;
        }
      }).open();
    },

    async submitForm() {
      console.log(this.company);
      base
        .newCompany(this.company)
        .then(res => {
          console.log(res);
          if (res.status == 201) {
            alert("신규 회사를 등록했습니다.");
            window.location.reload();
          } else {
            alert(res.status);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
      this.clearForm();
    },

    clearForm() {
      for (const key in this.company) {
        this.company[key] = "";
      }
    }
  },
  setup() {
    // const validationSchema = Yup.object({
    //   cmpName: Yup.string().required("필수값입니다."),
    //   cmpAddr: Yup.string().required(),
    //   cmpRdpName: Yup.string().required(),
    //   cmpTel: Yup.string().required(),
    //   cmpEmail: Yup.string().required(),
    // });
    //   const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // const modalRef = ref<null | HTMLElement>(null);
    //   const apiData = ref<APIData>({
    //     apiName: "",
    //     companyName: "",
    //     category: "",
    //     apiMethod: "",
    //   });
    // const validationSchema = Yup.object().shape({
    //   cmpName: Yup.string().required("필수값입니다."),
    //   cmpAddr: Yup.string().required(),
    //   cmpRdpName: Yup.string().required(),
    //   cmpTel: Yup.string().required(),
    //   cmpEmail: Yup.string().required(),
    // });
    //   const submit = () => {
    //     console.log(submitButtonRef);
    //     if (!submitButtonRef.value) {
    //       return;
    //     }
    //       console.log(apiData.value);
    //       //Disable button
    //       submitButtonRef.value.disabled = true;
    //       // Activate indicator
    //       submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    //       setTimeout(() => {
    //         if (submitButtonRef.value) {
    //           submitButtonRef.value.disabled = false;
    //           submitButtonRef.value?.removeAttribute("data-kt-indicator");
    //         }
    //         Swal.fire({
    //           text: "Form has been successfully submitted!",
    //           icon: "success",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         }).then(() => {
    //           window.location.reload();
    //         });
    //       }, 2000);
    //     };
    // return { validationSchema };
  }
});
